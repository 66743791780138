import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    IconButton,
    ListItemIcon
} from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const DramerMenu = () => {
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false)
    return (
        <>
            <Drawer open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                
                <List sx={{ width: "100%", height: "100%", backgroundColor: "#123416", color: "#FFFFFF" }}>
                <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                        <ListItemText sx={{ color: "#F6F6F6" }} onClick={() => navigate('/Acceuil', { replace: true })}>
                           <img src="../assets/images/icons.png" alt="Product Image" />
                        </ListItemText>
                        
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText sx={{ color: "#F6F6F6" }} onClick={() => navigate('/Acceuil', { replace: true })}>Acceuil <ArrowRightIcon sx={{ml:12}} /></ListItemText>
                        </ListItemIcon>
                    </ListItemButton>

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText sx={{ color: "#F6F6F6" }} onClick={() => navigate('/Apropos', { replace: true })}>Apropos <ArrowRightIcon sx={{ml:11}} /></ListItemText>
                        </ListItemIcon>
                    </ListItemButton>

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText sx={{ color: "#F6F6F6" }} onClick={() => navigate('/Projets', { replace: true })}>Projtes <ArrowRightIcon sx={{ml:12}} /></ListItemText>
                        </ListItemIcon>
                    </ListItemButton>

                    <ListItemButton sx={{ color: "#F6F6F6" }} onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText sx={{ color: "#F6F6F6" }} onClick={() => navigate('/Don', { replace: true })}>Faire Don <ArrowRightIcon sx={{ml:10}} /></ListItemText>
                        </ListItemIcon>
                    </ListItemButton>

                    <ListItemButton sx={{ color: "#F6F6F6" }} onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText sx={{ color: "#F6F6F6" }} onClick={() => navigate('/Contact', { replace: true })}>Contacts <ArrowRightIcon sx={{ml:10}} /></ListItemText>
                        </ListItemIcon>
                    </ListItemButton>
                </List>
            </Drawer>
            <IconButton sx={{ marginLeft: "auto" }} onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
            </IconButton>
        </>
    )
}

export default DramerMenu
