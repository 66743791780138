import React, { Suspense } from 'react';
import { HashRouter , Routes, Route} from 'react-router-dom';
import DefaultLayout from './Layout/DefaultLayout';

class App extends React.Component {
 render() {
  return (
    <>
      <HashRouter>
         <Suspense>
         <Routes>
            <Route exact path='*' name="Acceuil" element={<DefaultLayout />} />
         </Routes>
         </Suspense>
      </HashRouter>
    </>
   );
 
 }  
}

export default App;
