import React from 'react'
import { Link } from 'react-router-dom'
import img17 from '../components/images/img17.jpg'

const Footer = () => {
    return (

        <>
            <footer className="site-footer">
                <div className="footer-widget-area wrd-cover-bg">
                    <div className="footer-left-image">
                        <img src={img17} alt=""/>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="widget col-lg-3 col-md-0 td_blank_widget"></div>
                            <div className="widget col-lg-3 col-md-6 widget_nav_menu">
                                <h4 className="widget-title">Nos Pages</h4>

                                <ul className="menu">
                                    <li><Link to="/Acceuil">Acceuil</Link></li>
                                    <li><Link href="/Apropos">Apropos</Link></li>
                                    <li><Link href="/Projets">Projets</Link></li>
                                    <li><Link href="/Don">Faire un Don</Link></li>
                                    <li><Link href="/Contact">Contact</Link></li>

                                </ul>
                            </div>

                            <div className="widget col-lg-3 col-md-6 widget_td_contact_info_widget">
                                <h4 className="widget-title">Adresse</h4>


                                <ul className="wrd-list-style widget-contact-info-list">
                                    <li className="widget-address-desc">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <div className="widget-addr-dsc">
                                            <p>Kirumba au Q. Kinawa, AV Amani
                                                N°28/E sur l’immeuble ACUCOBA près du grand marché de Kirumba.</p>
                                        </div>
                                    </li>
                                </ul>

                            </div>

                            <div className="widget col-lg-3 col-md-6 widget_td_contact_info_widget">
                                <h4 className="widget-title">Contact Info</h4>


                                <ul className="wrd-list-style widget-contact-info-list">
                                  
                                    <li><a href="tel:0971007583"><i className="fas fa-mobile-alt"></i>+243 971007583,815918982</a></li>

                                    <li><a href="mailto:acucobardc@gmail.com"><i className="fas fa-paper-plane"></i>acucobardc@gmail.com</a></li>
                                </ul>

                            </div>

                           
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="footer-social-icon social-icons">
                                    <ul className="wrd-list-style wrd-list-inline">
                                        <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                    
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="site-copyright-text">
                                    &copy; Mlinzi corporation | All Right Reserved
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="scroll-to-top"><i className="flaticon-leaf"></i></div>
            </footer>
        </>
    )
}

export default Footer
