
import React from 'react'
import { Link } from 'react-router-dom'
import {useMediaQuery, useTheme} from '@mui/material'
import DramerMenu from './DramerMenu';

const Header = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"))
    return (
        <>
            <div className="mobile-menu-container">
                <div className="mobile-menu-close"></div>
                <div id="mobile-menu-wrap"></div>
            </div>



            <div className="toggle-bg"></div>
            <div className="toggle-cart-products">
                <div className="toggle-close">x</div>
                <div className="widget_shopping_cart">
                    <div className="widget_shopping_cart_content">
                        <ul className="product_list_widget ">
                            <li className="woocommerce-mini-cart-item mini_cart_item">
                                <a href="#" className="remove_from_cart_button">×</a>
                                <a href="#">
                                    <img src="../assets/images/shop/cart-product-1.jpg" alt="Product Image" />
                                    Flower With Vase
                                </a>
                                <span className="quantity">1 × $18.00</span>
                            </li>

                            <li className="woocommerce-mini-cart-item mini_cart_item">
                                <a href="#" className="remove_from_cart_button">×</a>
                                <a href="#">
                                    <img src="../assets/images/shop/cart-product-2.jpg" alt="Product Image" />
                                    Fruit With Basket
                                </a>
                                <span className="quantity">1 × $22.00</span>
                            </li>

                            <li className="woocommerce-mini-cart-item mini_cart_item">
                                <a href="#" className="remove_from_cart_button">×</a>
                                <a href="#">
                                    <img src="../assets/images/shop/cart-product-3.jpg" alt="Product Image" />
                                    Fertilizer Bag
                                </a>
                                <span className="quantity">1 × 48.00</span>
                            </li>

                            <li className="woocommerce-mini-cart-item mini_cart_item">
                                <a href="#" className="remove_from_cart_button">×</a>
                                <a href="#">
                                    <img src="../assets/images/shop/cart-product-4.jpg" alt="Product Image" />
                                    Hand Shovel
                                </a>
                                <span className="quantity">1 × $18.00</span>
                            </li>
                        </ul>

                        <p className="woocommerce-mini-cart__total">
                            Subtotal:
                            <span className="amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>126.00</bdi></span>
                        </p>

                        <p className="woocommerce-mini-cart__buttons">
                            <a href="#">View cart</a>
                            <a href="#">Checkout</a>
                        </p>
                    </div>
                </div>
            </div>

            <header className="header-area site-header">

                <div className="main-menu-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-md-4 col-5">
                                <div className="site-branding">
                                    <Link to="/Acceuil">
                                        <img src="../assets/images/icons.png" alt="onland" />
                                    </Link>
                                </div>
                            </div>

                            {
                                isMatch ? (
                                    <>
                                      <DramerMenu />
                                    </>
                                ) : (
                                    <>
                                        <div className="col-lg-10 col-md-8 col-7 d-flex align-items-center justify-content-end">
                                <div className="header-navigation-area">
                                    <nav className="main-navigation">
                                        <div className="main-menu-container">
                                            <ul id="main-menu" className="menu">
                                                <li className="menu-item-has-children current-menu-ancestor current-menu-item">
                                                    <Link to="/Acceuil">Acceuil</Link>
                                                </li>

                                                <li><Link to="/Apropos">Apropos</Link></li>

                                                <li className="menu-item-has-children">
                                                    <Link to="/Projets">Projets</Link>

                                                </li>

                                                <li className="menu-item-has-children">
                                                    <Link to="/Don">Faire Don</Link>

                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>

                                <div className="header-buttons-area">
                                    <ul className="header-buttons-wrapper wrd-list-style d-flex justify-content-end align-items-center">

                                        <li className="header-button"><Link className="wrd-button wrd-deep-bg" to="/Contact">Contact<i className="flaticon-phone-call"></i></Link></li>
                                        {/* <li className="mobile-menu-trigger"><span></span><span></span><span></span></li> */}
                                    </ul>
                                </div>
                            </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </header>
        </>

    )
}

export default Header
