import React from "react";

const acceauil = React.lazy(() => import('./views/Acceuil'))
const apropos = React.lazy(() => import('./views/Apropos'))
const projets = React.lazy(() => import('./views/Projets'))
const don = React.lazy(() => import('./views/Don'))
const contact = React.lazy(() => import('./views/Contact'))

const routes = [
    {path: '/', exact: true, name:'Acceuil'},
    {path: '/Acceuil', name: 'Acceuil', element: acceauil},
    {path: '/Apropos', name: 'Apropos', element: apropos},
    {path: '/Projets', name: 'Projets', element: projets},
    {path: '/Don', name: 'Don', element: don},
    {path: '/Contact', name: 'Contact', element: contact}
]

export default routes