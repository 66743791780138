import React from 'react'
import AppContent from '../components/AppContent'
import Footer from '../components/Footer'
import Header from '../components/Header'

const DefaultLayout = () => {
  return (
    <div class="site header-style-one">
      <Header />
      <AppContent />
      <Footer/>
    </div>
  )
}

export default DefaultLayout
